<template>
  <div>
    <div v-if="$store.state.auth.user.active_thread_id" >
      <AIChatInterface
      :assistantId="$store.state.eddie.mainAssistantId"
      :threadId="$store.state.auth.user.active_thread_id"
      :vectorStoreId="$store.state.auth.user.user_vector_store"
      :initialQuestion="$route.query.prompt"
      />
    </div>
    <div v-else> Not available enough data </div>
  </div>
</template>

<script>
import AIChatInterface from '../widgets/SmartGraphWidget/AIChatInterface.vue';

export default {
  data() {
    return {

    };
  },
  components: {
    AIChatInterface,
  },
};
</script>

<style lang="scss" scoped>

</style>
